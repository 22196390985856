.news-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 15px;
    width: 90%;
    /* width: 50%; */
    margin-right: 5px;
    justify-content: flex-start; /* Adjust this line */

}
.search-container {
    margin: 20px; /* Adds some spacing around the search box */
}

.search-container input {
    padding: 8px;
    margin-top: 10px; /* Adds some space at the top */
    width: 200px; /* Adjust the width as needed */
    border-radius: 5px; /* Adds rounded corners to the input field */
    border: 1px solid #ccc; /* Adds a border */
}

.search-news-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 15px;
    width: 100%;
    /* width: 50%; */
    margin-right: 5px;
    justify-content: flex-start; /* Adjust this line */

}

.news-heading {
    text-align: center;
}

.news-body-cont {
    display: flex;
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
    flex-direction: row;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
}

.side-bar-ad {
    /* width: auto; */
}

.news-ads-cont {
    display: flex;
    flex-direction: column;
    width: 28%;
    gap: 20px;
    margin-right: 7%;
}

.news-horizantal-ads {
    width: 90%; /* Set width to 100% of its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its original width */
    max-height: 100%; /* Ensure image does not exceed its original height */
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  

  .search-container {
    width: 90%;
    margin: 20px auto;
    text-align: center;
}

.search-input {
    padding: 10px;
    width: 40%;
    font-size: 1rem;
    border: 2px solid #ddd; /* Light grey border */
    border-radius: 5px;
    outline: none;
}

/* Adjust width on smaller screens */
@media (max-width: 768px) {
    .search-input {
        width: 80%;
    }
}


.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px; /* Adjust based on your design */
}

.pagination-container button {
    padding: 10px 20px;
    background-color: #b7b4b48d; /* Adjust based on your design */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-container button:hover {
    background-color: #e0e0e0; /* Adjust based on your design */
}

.pagination-container button:disabled {
    background-color: #ccc;
    cursor: default;
}
.pagination-container button.active {
    background-color: #007bff; /* Bootstrap primary color for example */
    color: white;
}

.pagination-container span {
    padding: 10px 20px;
}

/* You may not need this if the above styles work for you */
.coming-soon-container {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center the text inside the container */
    height: 100%; /* Take full height */
    font-family: 'Helvetica', sans-serif; /* Apply the font */
}

.news-body-cont222 {
    display: flex; /* Set the display to flex */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    height: 50vh; /* Take full height of the viewport */
}

.news-cards222 {
    display: flex; /* Flex container for its children */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    width: 100%; /* Take full width */
}



@media screen and (max-width: 768px) {

    .news-cards {
       
        width: 100%;
    
    }
    .search-container {
        margin: 20px; /* Adds some spacing around the search box */
    }
    
    .search-container input {
        padding: 8px;
        margin-top: 10px; /* Adds some space at the top */
        width: 200px; /* Adjust the width as needed */
        border-radius: 5px; /* Adds rounded corners to the input field */
        border: 1px solid #ccc; /* Adds a border */
    }
    
    .search-news-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 15px;
        width: 100%;
        /* width: 50%; */
        margin-right: 5px;
        justify-content: flex-start; /* Adjust this line */
    
    }
    
    .news-heading {
        text-align: center;
    }
    
    .news-body-cont {
        margin-top: 30px;
        flex-direction: column;
        /* flex-wrap: wrap; */
    }
    
    .side-bar-ad {
        /* width: auto; */
    }
    
    .news-ads-cont {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        margin-right: 7%;
    }
    
    .news-horizantal-ads {
        width: 90%; /* Set width to 100% of its container */
        height: auto; /* Automatically adjust height to maintain aspect ratio */
        max-width: 100%; /* Ensure image does not exceed its original width */
        max-height: 100%; /* Ensure image does not exceed its original height */
        display: block; /* Ensure the image behaves as a block element */
        margin: 0 auto; /* Center the image horizontally */
      }
      
    
      .search-container {
        width: 90%;
        margin: 20px auto;
        text-align: center;
    }
    
    .search-input {
        padding: 10px;
        width: 40%;
        font-size: 1rem;
        border: 2px solid #ddd; /* Light grey border */
        border-radius: 5px;
        outline: none;
    }
    
    /* Adjust width on smaller screens */
    @media (max-width: 768px) {
        .search-input {
            width: 80%;
        }
    }
    
    
    .pagination-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px; /* Adjust based on your design */
    }
    
    .pagination-container button {
        padding: 10px 20px;
        background-color: #b7b4b48d; /* Adjust based on your design */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .pagination-container button:hover {
        background-color: #e0e0e0; /* Adjust based on your design */
    }
    
    .pagination-container button:disabled {
        background-color: #ccc;
        cursor: default;
    }
    .pagination-container button.active {
        background-color: #007bff; /* Bootstrap primary color for example */
        color: white;
    }
    
    .pagination-container span {
        padding: 10px 20px;
    }
    
    /* You may not need this if the above styles work for you */
    .coming-soon-container {
        display: flex; /* Enable flexbox */
        flex-direction: column; /* Stack items vertically */
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center the text inside the container */
        height: 100%; /* Take full height */
        font-family: 'Helvetica', sans-serif; /* Apply the font */
    }
    
    .news-body-cont222 {
        display: flex; /* Set the display to flex */
        justify-content: center; /* Center children horizontally */
        align-items: center; /* Center children vertically */
        height: 50vh; /* Take full height of the viewport */
    }
    
    .news-cards222 {
        display: flex; /* Flex container for its children */
        flex-direction: column; /* Stack children vertically */
        justify-content: center; /* Center children horizontally */
        align-items: center; /* Center children vertically */
        width: 100%; /* Take full width */
    }
    
    
    
}