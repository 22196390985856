.insider-body {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 90%;
}

.insider-details {
    height: 100%;
    width: 80%;
    padding-right: 50px;
    margin-right: 5px;
}

.insider-sidebar-ads {
    margin-top: 10px;
    width: 35%;
    height: auto;
    margin-bottom: 20px;
}

.insider-latest-updates {
    margin: 10px 0px;
    padding: 8px 15px;
    width: max-content;
    background-color: #dddddd;
    color: #000;
    font-size: 18px;
    font-weight: bold;

    font-family: 'Helvitica';
}

.insider-updates {
    line-height: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 450;
    border-bottom: 1px solid rgb(72, 71, 71);
}

.insider-last-update {
    border-bottom: none;
}

.insider-ads {
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.indiser-ad-div {
    background-image: url('../../../assets/home-page-assets/Post-assets/balnk_ads.svg');
    width: 100%;
    height: 250px;
}

.insider-updates > a {
    color: #000;
}

.insider-updates > a:hover {
    color: #000;
    opacity: 0.7;
}

.insider-xl-ads {
    display: none;
}

.insider-post {
    width: 90%;
    margin: auto;
    margin-top: 40px;
}

.insider-post-heading {
    border-bottom: 1px solid black;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.insider-post-heading-text {
    background-color: #000;
    color: #fff;
    padding: 7px 45px;
    width: max-content;
    margin-bottom: 0px;
}

.insider-viewMoreImg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    height: 24px;
    width: 80px;
    align-items: self-start;
}

.insider-post-items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

/* @media screen and (max-width: 977px) {
    .insider-post-items {
        display: flex;
        gap: 20px;
        flex-direction: column;
        flex-wrap: wrap;
    } 
} */

@media screen and (max-width: 1100px) {
    
    .insider-updates {
        font-size: 14px;
    }

}

@media screen and (max-width: 970px) {
    
    .insider-body {
        flex-direction: column;
    }

    .insider-latest {
        margin-bottom: 0px;
    }

    .insider-ads {
        height: max-content;
      width: 100%;
    }
    .side-bar-ad{
        width: 100%;
    }


    .insider-details {
        width: 100%;
        margin-right: 0px;
        padding-right: 0px;
    }

    .insider-sidebar-ads {
        width: 100%;
        margin-bottom: 0px;
    }

    .insider-latest {
        display: flex;
        flex-direction: row;
        gap: 5%;
        flex-wrap: wrap;
        width: 100%;
        /* margin-bottom: 30px; */
    }

    .insider-updates {
        width: 40%;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .insider-last-update {
        border-bottom: 1px solid rgb(72, 71, 71);
    }
}

@media screen and (min-width: 1650px) {

    .insider-updates {
        font-size: 26px;
        line-height: 40px;
    } 

}


.ad-image{
    width: 100%;
    height: 100%;
}









