.footer-main {
    width: 100%;
    background-color: #E9F1FC;
    bottom: 0px;
    /* text-wrap: nowrap; */
    color: #085EAB;
    margin-top: 30px;
    /* padding-bottom: 20px; */
    /* padding-top: 30px; */
    position: relative;
    bottom: 0px;
}

.footer-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    color: #085EAB;
    /* padding-top: 40px; */
    /* padding-bottom: 20px; */
    margin: auto;
    margin-bottom: 0px;
    justify-content: space-between;
}

.disclaimer{
    color: #000000;
    opacity: 0.7;
    font-Size: 12px;
    padding-bottom:20px;
    font-family:"Helvetica" 
}

.subscribe-text> :first-child {
    padding-top: 15px;
    font-size: 28px;
}

.subscribe-text> :last-child {
    opacity: 0.7;
}

.subscribe-input {
    align-items: center;
    justify-content: flex-end;
    display: flex;
}

.input-field {
    height: 30px;
    width: 400px; /* Adjust the width value as needed */
    font-size: 0.8rem;
    padding: 0px 15px;
    color: #131313;
}


.subscribe-input> :last-child {
    background-color: #085EAB;
    color: #ffff;
    width: 200px;
    border: #000;
    margin-left: 8px;
    height: 30px;
    border-radius: 5px;
}

.footer-body {
    width: 90%;
    margin: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
}

.footer-icon:hover {
    cursor: pointer;
    opacity: 0.7;
}

.our-brands {
    display: flex;
    flex-direction: column;
    padding-right: 50px
}

.our-brands-heading {
    padding: 0px;
    font-size: 16px;
    margin: 0px;
    padding-bottom: 20px;
    padding-top: 30px;
}

.footer-subheadings {
    display: flex;
    gap: 25px;
  
}

.footer-copyright {
    font-size: 10px;
    padding-top: 1rem;
    opacity: 0.9;
    display: flex;
    justify-content: center;
}

.footer-links {
    color: #000000;
    font-size: 12px;
    opacity: 0.7;
    padding: 2px 0px;
    width: max-content;
}

.footer-links:hover {
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    /* color: #1D7ED4; */
}

.footer-img {
    display: flex;
    padding-top: 30px;
    padding-bottom: 15px;
    justify-content: center;
}

.footer-logo {
    width: 160px;
    display: flex;
    justify-content: center;
}

.footer-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0px;

}

.footer-icons {
    display: flex;
    gap: 15px;
    justify-content: center;
}



/* Responsive Media Queries */
@media (max-width: 1024px) {
    .footer-container,
    .footer-body {
        flex-direction: column;
        align-items: center;
    }

    .subscribe-input,
    .footer-subheadings {
        flex-direction: column;
    }

    .input-field {
        width: 90%; /* Make input field wider on smaller screens */
    }

    .subscribe-input> :last-child,
    .our-brands {
        width: auto; /* Make button full width of the input field */
        margin-top: 10px; /* Add spacing between elements */
    }

    .our-brands-heading {
        text-align: center;
    }

    .footer-links {
        text-align: center;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .footer-logo {
        width: 120px; /* Reduce logo size on smaller screens */
    }

    .footer-icon {
        margin: 0 10px; /* Reduce space between icons */
    }

    .our-brands {
        padding-right: 0;
    }

    .subscribe-text> :first-child,
    .subscribe-text> :last-child {
        text-align: center;
    }
}