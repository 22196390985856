
/*--------------------------------------------------------------
## Top Stories
--------------------------------------------------------------*/
.top-stories-bar {
	background-color: #ffffff;
	position: relative;
	overflow: hidden;
	font-weight: 700;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
	border-top: 1px solid #085EAB; /* Add a thin red top border */
}

.top-stories-bar:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50%;
	background-color: #dddddd;
}
.top-stories-bar {
	height: 50px;
}
.top-stories-bar .top-stories-label {
	float: left;
	color: #333333;
	padding-left: 15px;
	margin-left: -15px;
	margin-right: -15px;
	background-color: #dddddd;
	position: relative;
	height: 100%;
	display: table;
}
.top-stories-bar .top-stories-label:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: -19%;
	width: 39%;
	background-color: #dddddd;
	transform: skew(20deg);
	-webkit-transform: skew(20deg);
	-moz-transform: skew(20deg);
	-ms-transform: skew(20deg);
	z-index: 1;
}
.top-stories-bar .top-stories-label .top-stories-label-wrap {
	position: relative;
	display: table;
	height: 100%;
	padding: 10px 0 10px 35px;
}
.top-stories-bar .top-stories-label .label-txt{
	position: relative;
	z-index: 2;
	display: table-cell;
	height: 30px;
	vertical-align: middle;
	font-size: 18px;
}
.top-stories-bar .top-stories-label .flash-icon {
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	background-color: #d64949;
	top: 50%;
	margin-top: -5px;
	left: 10px;
	z-index: 2;
}
.top-stories-bar .top-stories-label .flash-icon:before {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border: 1px solid #d64949;
	top: 50%;
	margin-top: -5px;
	left: 50%;
	margin-left: -5px;
	animation-name: blink-a;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}
@keyframes blink-a {
	0%   {transform: scale(1, 1);}
	100%  {transform: scale(3, 3); opacity: 0;}
}
.top-stories-bar .top-stories-label .flash-icon:after {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border: 1px solid #d64949;
	top: 50%;
	margin-top: -5px;
	left: 50%;
	margin-left: -5px;
	animation-name: blink-b;
	animation-duration: 2s;
	animation-delay: 1s;
	animation-iteration-count: infinite;
}
@keyframes blink-b {
	0%   {transform: scale(1, 1);}
	100%  {transform: scale(3, 3); opacity: 0;}
}
.top-stories-bar .top-stories-lists {
	display: block;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;
	padding-left: 0;
	background-color: #ffffff;
	height: 50px;
	align-items: center;
}
.top-stories-bar .top-stories-lists .row {
	align-items: center;

	height: 50px;
}
.top-stories-bar .js-marquee {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	height: 50px;
}
.top-stories-bar .top-stories-lists .marquee {
	width: 100%;
	overflow: hidden;
	height: 50px;
	align-items: center; /* Vertically center items */

}
.top-stories-bar .top-stories-lists .marquee a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 50px;
	color: #333333;
	height: 50px;
}
.top-stories-bar .top-stories-lists .marquee a:hover {
	color: #085EAB;
    font-weight: bold;
	align-items: center;

}
.top-stories-bar .top-stories-lists:after {
	content: "";
	position: absolute;
	top: 0;
	right: 15px;
	bottom: 0;
	width: 80px;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
	align-items: center;

}



/* Keyframes for scrolling animation */
@keyframes scrollLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .marquee {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500;
  }
  
  .marquee a {
    display: inline-block;
    padding-left: 50px; /* Adjust as necessary */
    animation: scrollLeft 20s linear infinite; /* Adjust timing as needed */
  }
  
  /* Pause animation on hover */
  .marquee:hover a {
    animation-play-state: paused;
  }
  