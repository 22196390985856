.main-body {
    margin: auto;
    width: 90%;
}

.cover-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 40px;
}




.cover-item {
    width: 50%;
    
    /* height: 500px; */

}



.cover-details {
    width: 50%;
}

.cover-item-img {
    width: 560px;
    height: 400px;
}

.cover-item-text {
   font-size: 25px;
   margin: 0px; 
   font-weight: 500;
   color: #000;
}

.cover-details-latest {
    width: 100%;
}

.latest-stores {
    background-color:#dddddd;
    color: #000;
    width: max-content;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Helvetica'
}

.latest-text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 40px;
    padding-top: 10px;
    margin-bottom: 15px;
    font-family: 'Helvetica'

}

@media screen and (max-width: 1355px) {

    .latest-text {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 30px;
        padding-top: 10px;
    }
    
}

.latest-text > div {
    width: 45%;
    font-size: 14PX;
    line-height: 18px;
    font-weight: 500;
    margin-top: 0px;
    padding-bottom: 15px;
    border-bottom: 1PX solid rgb(153, 151, 151);
}

.editor-choice {
    display: flex;
    flex-direction: row;
    gap: 30px
}

.editor-choice-img {
    width: 50%;
}

.editor-choice-img > a > img {
    width: 300px;
}

.editor-chice-btn {
    width: max-content;
    background-color: #FF0000;
    color: #fff;
    padding: 5px 10px;
}

.editor-chice-text {
   padding: 10px 0px; 
}

.editor-choice-img-side {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

.editor-choice-img-side > div {
    height: 50%;
    background-image: url('../../assets//home-page-assets//Post-assets/blank_img.svg');
}

.post-heading {
    text-align: center;
}

.cover-post-items {
    display: flex;
    flex-wrap: wrap;
    width: 66%;
    /* flex-grow: 1; */
    column-gap: 35px;
}

.card-desc {
    display: flex;
    align-items: flex-end;
}

.card-box {
    display: flex;
    flex-grow: 1;
    width: 300px;
    flex-wrap: wrap;
}

.post-container-flex{
    display: flex;

}
.cover-sustain-container {
    display: flex;
    flex-grow: 1;
    gap: 25px;
    flex-wrap: wrap;
}

.cover-post-heading {
    
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.cover-post-heading-text {
    background-color:#dddddd;
    color: #000;
    padding: 7px 45px;
    width: max-content;
    font-size: 18px;
    margin-bottom: 0px;
}

.viewMoreImg {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-top: 17px;
    transform: scale(0.8);
    height: 15px;
    width: 80px;
    align-items: self-start;
    margin-bottom: 8px;
    

}

.cover-sustainability-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sustain-img-body {
    position: relative;
    width: 250px;
    height: 230px;
}

.sustain-caption {
    position: absolute;
    bottom: 0px;
    padding-left: 10px;
    text-wrap: wrap;
    opacity: 0.8;
    font-size: 14px;
    color: white; /* Text color */
    padding: 5px 10px /* Add some padding */
}

.viewMoreImg:hover {
    cursor: pointer;
}

.sustain-ads {
    display: flex;
    margin-top: 25px;
    width: 100%;
    height: 150px;
    background-color: rgb(59 130 246 / 0.5);
}

.event-ads-img {
    width: 20vw;
}

.cover-event-body-img {
    width: 76%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.event-img-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF0000;
    color: #fff;
    width: max-content;
}

.event-img-btn:hover {
    border: 1px solid #fff;
}

.event-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-img {
    width: 32vw;
    height: 30vh;
    background-image: url('../../assets/home-page-assets/grayTempImg.svg');
    display: flex;
    justify-content: center;
    align-items: center;

}

.cover-event-body {
    display: flex;
    gap: 20px;
}

.event-ads {
    background-image: url('../../assets/home-page-assets/squareGray.svg');
    width: 22vw;
}

.largeAds {
    background-image: url('../../assets/home-page-assets/largeAds.svg');
    background-size: cover; /* Cover the entire area */
    background-position: center; /* Center the background image */
    width: 100%;
    height: 120px; /* Adjust as needed */
}

.equip-img {
    background-image: url('../../assets/home-page-assets/Post-assets/sample_img.png');
    width: 100%;
    height: 200px;
    background-size: cover; /* Ensures the image fits inside the container */
    /* background-repeat: no-repeat;  */
    background-position: center; /* Centers the image in the container */

}

.event-card {
    display: flex;
    flex-grow: 1;
    width: 350px;
}

.event-ads-2 {
    display: none;
}

.card.image-full:before {
    border-radius: 0px;
}
.euip-item {
    width: 300px;
    height: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.equip-title {
    background-color: #FF0000;
    color: #fff;
    text-align: center;
    padding: 8px 0px;
}

.euip-items {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
}

.equip-desc {
    padding: 10px 0px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 15px;
}

.equip-desc:hover {
    cursor: pointer;
    opacity: 1.9;
    font-size: 16;
}

.spotlight-ads {
    width: 100%;
    background-image: url('../../assets/home-page-assets/largeAds.svg');
    height: 200px;
    margin-top: 10px;
}

.spotlight-body-post {
    height: auto;
    width: 55%;
}

.management-title {
    background-color: #FF0000;
    color: #fff;
    margin: 0px;
    width: fit-content;
    padding: 3px 10px;
}

.management-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    width: 100%;
}

/* .management-item > img {
    width: 300px;
} */

.management-item > h6 {
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
}

.management-item > p {
    font-size: 14px;
    margin: 0px;
}

.spotlight-body-manage {
    height: auto;
    width: 43%;
}
.editor-choice-img-side-1{
    height: 100px;
    width: 250px;
}

.spotlight, .equipment, .event, .sustain, .cover-post  {
    margin-bottom: 40px;
}


.spotlight-body-ads {
    height: auto;
    width: 22%;
    background-image: url('../../assets/home-page-assets/squareGray.svg');
}

.spotlight-body-ads-1 {
    height: auto;
    margin-bottom: 15px;
    width: 23%;
    background-image: url('../../assets/home-page-assets/squareGray.svg');
}

.spotlight-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 2%;
    flex-wrap: wrap;
}

.spotlight-desc {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
}

.insights-desc {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
}

.tab-ads-cont-2 {
    display: none;
}

.insights-item {
    background-image: url('../../assets//home-page-assets//grayTempImg.svg');
    height: 230px;
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.post-cardm {
    display: block;
    text-decoration: none;
    color: inherit; /* Ensure text colors inherit from the card, not the link */

  }


.insights-items {
    width: 22%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.insights-btn {
    border: 1px solid #085EAB;
    color: #085EAB;
    font-weight: bold;
    padding: 10px 20px;
    width: max-content;
    background-color: #fff;
}

.postStyle {
    width: auto;
}

.insights-btn:hover {
    border: 1px solid #fff;
    background-color: #085EAB;
    color: #fff;
}



.insights-container {
    display: flex;
    gap: 25px;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.insights {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.insights-ads {
    height: 200px;
    width: 100%;
    background-image: url('../../assets/home-page-assets//squareGray.svg');
}


.scroll-container {
    overflow: hidden;
    white-space: nowrap;
}

.scroll-content {
    display: inline-block;
    animation: scroll 50s linear infinite;
}

.scroll-item {
    display: inline-block;
    padding: 0 20px; /* Adjust spacing between items */
}





.horizantal-ads {
    width: 90%; /* Set width to 100% of its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its original width */
    max-height: 100%; /* Ensure image does not exceed its original height */
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  


  
.horizantal-ads2 {
    width: 100%; /* Set width to 100% of its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its original width */
    max-height: 100%; /* Ensure image does not exceed its original height */
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
 

  .editor-choice-img:hover img {
    transform: scale(1.05); /* Enlarge the image by 5% on hover */
}


.header-util-btn2 {
    text-wrap: nowrap;
    color: #fff;
    border: 1px solid #000;
    display: flex;
    padding: 6px 10px;
    font-size: 0.8rem;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
    font-weight: 600;
    width: max-content;
    background-color: #085EAB;
    border-radius: 5px;
    margin-left: 100px;
    padding-left: 25px;
    padding-right: 25px; 

}


@media screen and (max-width: 1024px) and (min-width: 768px) {
    .cover-container {
        margin-bottom: 40px;     
           flex-direction: column; /* Stack items vertically */


    }
    .cover-item {
        width: 100%;
        
        /* height: 500px; */
    
    }
    .cover-item-img {
        width: 100%;
        height: min-content;

    }
    
    .cover-item-text {
       font-size: 25px;
       margin: 0px; 
       font-weight: 500;
       color: #000;
    }
    .cover-details {
        width: 100%;
    }
    .latest-text > div {
        width: 45%;
        font-size: 14PX;
        line-height: 18px;
        font-weight: 500;
        margin-top: 0px;
        padding-bottom: 15px;
        border-bottom: 1PX solid rgb(153, 151, 151);
    }
    .editor-choice-img {
        margin-top: 25px;
        align-items: center;
    }
    .editor-choice-img-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    
}


@media screen and (max-width: 768px) {
    .cover-container {
        margin-bottom: 40px;
        flex-direction: column; /* Stack items vertically */

    }
    .cover-item {
        width: 100%;
        
        /* height: 500px; */
    
    }
    .cover-item-img {
        width: 100%;
        height: min-content;


        
    }
    .editor-choice {
        display: flex;
        flex-direction: column;
    }
    
    
    .cover-item-text {
       font-size: 25px;
       margin: 0px; 
       font-weight: 500;
       color: #000;
    }
    .cover-details {
        width: 100%;
    }
    .latest-text > div {
        width: 45%;
        font-size: 14PX;
        line-height: 18px;
        font-weight: 500;
        margin-top: 0px;
        padding-bottom: 15px;
        border-bottom: 1PX solid rgb(153, 151, 151);
    }
    .editor-choice {
        display: flex;
        flex-direction: column;
    }
    .editor-choice-img {
        margin-top: 25px;
        align-items: center;
        width: 100%;
    }

    .editor-choice-img-side {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    .editor-choice-img-side-1{
       width: 100%;
       height:200px;
    }
    .post-container-flex{
        display: flex;
    flex-direction: column;
    }
    .cover-post-items {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /* flex-grow: 1; */
        
    }
    .insights-container {
        display: flex;
        gap: 25px;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 20px;
        align-items: center;
    }
    

.insights-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border : 1PX solid rgb(153, 151, 151);

    /* flex-grow: 1; */
}
.horizantal-ads {
    width: 90%; /* Set width to 100% of its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its original width */
    max-height: 100%; /* Ensure image does not exceed its original height */
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  


  
.horizantal-ads2 {
    width: 100%; /* Set width to 100% of its container */
    height: auto; /* Automatically adjust height to maintain aspect ratio */
    max-width: 100%; /* Ensure image does not exceed its original width */
    max-height: 100%; /* Ensure image does not exceed its original height */
    display: block; /* Ensure the image behaves as a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
 
}