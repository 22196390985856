.thank-you-container {
    margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.thank-you-card {
    max-width: 600px;
    background: white;
    padding: 40px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.thank-you-card h1 {
    color: #007bff;
    margin-bottom: 20px;
}

.contact-info p {
    color: #333;
    margin: 10px 0;
}

.tqbutton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tqbutton:hover {
    background-color: #0056b3;
}
