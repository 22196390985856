.horizontal-ads {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%; /* Adjust the width as needed */
    margin-top: 20px;
    margin-bottom: 20px;
}

.horizontal-ads img {
    height: auto;
    max-height: 120px;
    margin-left: 20px; /* Adjust the left margin */
    margin-right: 20px; /* Adjust the right margin */
}

.editorial-body {
    margin: auto;
    width: 90%;
}

.editorial {
    margin: 40px 0;
}

.share-image {
    width: 160px; /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
}


.editorialviewMoreImg:hover {
    cursor: pointer;
}

.editorialviewMoreImg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    transform: scale(0.7);
}

.editorial-heading {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.editorial-heading-text {
    background-color: #dddddd;
    color: #000;
    padding: 7px 45px;
    width: max-content;
    margin-bottom: 0px;
}


.editorial-img {
    background-image: url('../../assets/home-page-assets/Post-assets/sample_img.png');
    width: 100%;
    height: 250px;
    background-size: cover; /* Ensures the image fits inside the container */
    /* background-repeat: no-repeat;  */
    background-position: center; /* Centers the image in the container */

}

.editorial-item {
    text-decoration: none;
    width: 200px;
    height: auto;
}

.editorial-item1 a {
    font-family: 'Helvitica';
    text-decoration: none; /* This removes the underline */
    color: #0f0f0f; /* This sets the text color */
    font-weight: bold; /* This sets the font weight */
  }
  

.editorial-title {
    /* background-color: #FF0000; */
    color: #0f0f0f;
    text-align: center;
    padding: 8px 0px;
    font-weight: bold;
}

.editorial-items {
    text-decoration: none;

    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}
